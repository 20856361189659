footer {
    margin-top: 5rem;
    background-color: var(--ukaBlue);
    color: var(--paleWhite);
    font-weight: 200;
    font-size: 0.9rem;
    padding: 10px;
}

footer span {
    font-weight: 600;
}