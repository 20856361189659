.button {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  border: none;
  border-radius: 3px;
  font-family: "Oxygen";
}

.button:focus {
  outline: none;
}

.button-outline {
  border: 1px solid darkgray;
  background-color: #fff;
}

/* .button-green {
  background: linear-gradient(320deg, rgba(109, 171, 84, 1) 20%, rgba(86, 130, 68, 1) 80%);
  color: var(--paleWhite);
} */
.button-green {
  background: linear-gradient(90deg, rgba(200, 60, 155, 1) 20%, rgb(201, 89, 166) 80%);
  color: var(--paleWhite);
}

.button-blue {
  background: linear-gradient(90deg, rgba(200, 60, 155, 1) 20%, rgb(201, 89, 166) 80%);
  color: var(--paleWhite);
}

.button-red {
  background: linear-gradient(90deg, rgba(170, 27, 17, 1) 20%, rgba(212, 72, 107, 1) 80%);
  color: var(--paleWhite);
}

.button-small {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.button-link {
  background-color: unset;
  color: var(--ukaBlue);
  cursor: pointer;
  padding-left: unset;
  padding-right: unset;
}

.button-link:focus {
  outline: none;
}

@media screen and (max-width: 900px) {
  .button {
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  }
}
