.members-bar {
    background: var(--ukaBlue);
    padding-left: 1rem;
    color: var(--paleWhite);
    display: grid;
    grid-template-columns: 25% 67.5% 7.5%;
}


@media screen and (max-width: 768px) {

    .members-bar {
        grid-template-columns: 100%;
    }

}