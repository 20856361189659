/* W3Schools modal */

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 900; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */
  max-height: 90vh;
  overflow-y: scroll;
}

.modal-content p,
td,
li,
label {
  color: var(--lessBlack);
}

.modal-content-narrow {
  width: 25%;
}
.modal-content-wide {
  width: 95%;
}

.fadeIn {
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 100%;
    margin: 50% auto;
  }
}
