.card {
    border: 1px solid silver;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 5px 15px 15px silver;
}


.card-narrow {
    max-width: 900px;
    margin: 0 auto;
}

.card > div {
    padding: 0.5rem;
}

.card-header {
    background-color: var(--ukaBlue);
    color: var(--paleWhite);
}

.card-body {
    background-color: #fcfcfc;
}

.card-body.fixed-height {
    height: 600px;
    overflow: scroll;
}