.grid,
.grid-fixed {
  display: grid;
  grid-template-columns: 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33%;
}

.grid-50-50 {
  grid-template-columns: 45% 45%;
}

.grid-33-66 {
  grid-template-columns: 32% 64%;
}

.grid-thirds {
  grid-template-columns: 32% 32% 32%;
}

.grid.spaced > div {
  padding-right: 2rem;
}
.grid.spaced-wide > div {
  padding-right: 4rem;
}

.col-1 {
  grid-column: span 1;
}

.col-2 {
  grid-column: span 2;
}

.col-3 {
  grid-column: span 3;
}

.col-3 {
  grid-column: span 3;
}

.col-4 {
  grid-column: span 4;
}

.col-5 {
  grid-column: span 5;
}

.col-6 {
  grid-column: span 6;
}

.col-7 {
  grid-column: span 7;
}

.col-8 {
  grid-column: span 8;
}

.col-9 {
  grid-column: span 9;
}

.col-10 {
  grid-column: span 10;
}

.col-11 {
  grid-column: span 11;
}

.col-12 {
  grid-column: span 12;
}

.border-left {
  border-left: 1px dotted silver;
}

.border-right {
  border-right: 1px dotted silver;
}

.border-top {
  border-top: 1px dotted silver;
}

.border-bottom {
  border-bottom: 1px dotted silver;
}

.border-all {
  border: 1px solid silver;
}

.col-full-1 {
  grid-column: span 1;
}
.col-full-2 {
  grid-column: span 2;
}
.col-full-3 {
  grid-column: span 3;
}
.col-full-4 {
  grid-column: span 4;
}
.col-full-5 {
  grid-column: span 5;
}
.col-full-6 {
  grid-column: span 6;
}
.col-full-7 {
  grid-column: span 7;
}
.col-full-8 {
  grid-column: span 8;
}
.col-full-9 {
  grid-column: span 9;
}
.col-full-10 {
  grid-column: span 10;
}
.col-full-11 {
  grid-column: span 11;
}
.col-full-12 {
  grid-column: span 12;
}

@media screen and (max-width: 900px) {
  .grid {
    grid-template-columns: 100%;
  }

  .grid-50-50 {
    grid-template-columns: 100%;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    grid-column: span 12;
  }

  .grid.spaced,
  .grid.spaced-wide {
    padding-right: unset;
  }

  .col-mobile-1 {
    grid-column: span 1;
  }
  .col-mobile-2 {
    grid-column: span 2;
  }
  .col-mobile-3 {
    grid-column: span 3;
  }
  .col-mobile-4 {
    grid-column: span 4;
  }
  .col-mobile-5 {
    grid-column: span 5;
  }
  .col-mobile-6 {
    grid-column: span 6;
  }
  .col-mobile-7 {
    grid-column: span 7;
  }
  .col-mobile-8 {
    grid-column: span 8;
  }
  .col-mobile-9 {
    grid-column: span 9;
  }
  .col-mobile-10 {
    grid-column: span 10;
  }
  .col-mobile-11 {
    grid-column: span 11;
  }
  .col-mobile-12 {
    grid-column: span 12;
  }
}
