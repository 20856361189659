@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@200;300;400;600&display=swap");
@import "./styles/Alerts.css";
@import "./styles/Buttons.css";
@import "./styles/Cards.css";
@import "./styles/Flex.css";
@import "./styles/Grid.css";
@import "./styles/Forms.css";
@import "./styles/Tables.css";
@import "./styles/Margins.css";
@import "./styles/Switch.css";

:root {
  --ukaBlue: rgb(200, 60, 155);
  --ukaRed: rgb(235, 160, 60);
  --success: rgb(109, 171, 84);
  --paleWhite: #efefef;
  --silver: #dedede;
  --nearBlack: #444;
  --lessBlack: #666;
}

html,
body {
  font-family: "Oxygen";
  font-weight: 200;
  font-size: 1rem;
  background-color: var(--paleWhite);
  color: var(--nearBlack);
  margin: 0;
  line-height: 1.6rem;
}

p {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

a {
  text-decoration: none;
  color: var(--ukaBlue);
}

.text-link {
  color: var(--ukaBlue);
}

.wtf {
  height: 25vw;
}

.narrow-lines {
  line-height: 1rem;
}

h1,
h2,
h3 {
  font-weight: 600;
  padding: 0;
  margin: 0;
}

h4,
h5,
h6 {
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.container {
  width: 95%;
  max-width: 1366px;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.5rem;
}

.sub-container {
  padding: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.semi-trans {
  opacity: 0.2;
}

.sub-menu {
  line-height: 2rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  border-bottom: 1px dotted var(--ukaBlue);
}

small {
  font-size: 0.8rem !important;
}

.text-white {
  color: var(--paleWhite) !important;
}

.text-red {
  color: var(--ukaRed) !important;
}

.text-green {
  color: green;
}

.text-blue {
  color: var(--ukaBlue) !important;
}

.text-bold {
  font-weight: 400;
  color: var(--nearBlack);
}

.text-center {
  text-align: center;
}
.sponsor-image-row {
  display: flex;
  flex-wrap: wrap;
}
.sponsor-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
}

.sponsor-image {
  max-height: 50px;
  width: auto;
  margin-bottom: 12px;
}

.sponsor-image-delete-link {
  color: var(--ukaRed);
  cursor: pointer;
}

ul.no-bullets {
  list-style-type: none;
}

.ring {
  min-height: 100px;
  border: 1px solid silver;
  padding: 10px;
  margin-right: 5px;
}

.ring-class {
  padding: 5px;
}
.ring-class-selected {
  background: var(--ukaBlue);
  color: var(--paleWhite);
  border-radius: 5px;
}

.ring-boxed {
  border: 2px solid black;
  padding: 5px;
}

@media screen and (min-width: 900px) {
  .hidden-large {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .hidden-mobile {
    display: none !important;
  }
}
