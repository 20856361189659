@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');



.quote {
    font-family: 'Nothing You Could Do';
    font-size: 1.2rem;
    color: var(--ukaRed);
}


.reg-progress-list {
    list-style-type: none;
}

.reg-progress-list li {
    margin-bottom: 5px;
}

.login-text {
    border: none;
    border-bottom: 1px solid silver;
    padding-left: none;
    outline: none;
}