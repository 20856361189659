.form-control {
    padding: 10px;
    font-size: 1rem;
    font-weight: 300;
    width: 95%;
    color: var(--lessBlack);
    border: 1px solid silver;
    font-family: 'Heebo';
}


.form-group > label {
    display: block;
}

.form-group > label.inline {
    display: inline;
}

select.form-control {
    font-size: 1rem;
}

.form-group {
    margin-bottom: 1rem;
}

.width-75 {
    width: 75%;
}

.width-50 {
    width: 50%;
}

.width-25 {
    width: 25%;
}

.width-20 {
    width: 20%;
}

.width-10 {
    width: 10%;
}


.form-label {
    color: var(--ukaBlue);
    font-weight: 400;
}

.form-label::after {
    content: "\A";
    white-space: pre;
}



.form-centered {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}


@media screen and (max-width: 768px) {
    .form-control.width-75 {
        width: 95%;
    }
    
    .form-control.width-50 {
        width: 95%;
    }
    
    .form-control.width-25 {
        width: 95%;
    }

    .form-control.width-10 {
        width: 50%;
    }    
}