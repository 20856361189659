.navbar {
  border-bottom: 1px solid var(--ukaRed);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

a.nav-item {
  display: inline;
  margin-right: 0.5rem;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  color: var(--ukaBlue);
  font-weight: 300;
  font-size: 0.9rem;
}
a.nav-item:hover {
  background-color: var(--silver);
}

a.nav-item:focus {
  outline: none;
}

a.nav-item.admin-link {
  justify-content: flex-end;
}

.responsive-menu-trigger {
  display: none;
  padding: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .navbar {
    flex-direction: column;
  }

  a.nav-item {
    margin-bottom: 0.2rem;
    display: none;
    order: 2;
  }

  a.nav-item.visible {
    display: block;
    font-size: 1.2rem;
  }

  .responsive-menu-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 1;
  }
}
