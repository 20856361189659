.Message {
    width: 100%;
    margin-top: 1.2rem;
    border-radius: 5px;
    padding: 0.3rem;
    padding-left: 1rem;
}

.Message-Green {
    background: green;
    color: var(--paleWhite);
}

.Message-Red {
    background:var(--ukaRed);
    color: var(--paleWhite);
}