@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@200;300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap);
.alert {
    padding: 5px;
    font-weight: 300;
}

.alert-success {
    background-color: var(--success) !important;
    color: var(--paleWhite) !important;
}

.alert-error {
    background-color: var(--ukaRed) !important;
    color: var(--paleWhite) !important;
}

.alert-warning {
    background-color: rgba(200, 175, 80, 0.5);
}

.alert-tint {
    border: 1px solid silver;
}
.button {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  border: none;
  border-radius: 3px;
  font-family: "Oxygen";
}

.button:focus {
  outline: none;
}

.button-outline {
  border: 1px solid darkgray;
  background-color: #fff;
}

/* .button-green {
  background: linear-gradient(320deg, rgba(109, 171, 84, 1) 20%, rgba(86, 130, 68, 1) 80%);
  color: var(--paleWhite);
} */
.button-green {
  background: linear-gradient(90deg, rgba(200, 60, 155, 1) 20%, rgb(201, 89, 166) 80%);
  color: var(--paleWhite);
}

.button-blue {
  background: linear-gradient(90deg, rgba(200, 60, 155, 1) 20%, rgb(201, 89, 166) 80%);
  color: var(--paleWhite);
}

.button-red {
  background: linear-gradient(90deg, rgba(170, 27, 17, 1) 20%, rgba(212, 72, 107, 1) 80%);
  color: var(--paleWhite);
}

.button-small {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.button-link {
  background-color: unset;
  color: var(--ukaBlue);
  cursor: pointer;
  padding-left: unset;
  padding-right: unset;
}

.button-link:focus {
  outline: none;
}

@media screen and (max-width: 900px) {
  .button {
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  }
}

.card {
    border: 1px solid silver;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 5px 15px 15px silver;
}


.card-narrow {
    max-width: 900px;
    margin: 0 auto;
}

.card > div {
    padding: 0.5rem;
}

.card-header {
    background-color: var(--ukaBlue);
    color: var(--paleWhite);
}

.card-body {
    background-color: #fcfcfc;
}

.card-body.fixed-height {
    height: 600px;
    overflow: scroll;
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.grid,
.grid-fixed {
  display: grid;
  grid-template-columns: 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33%;
}

.grid-50-50 {
  grid-template-columns: 45% 45%;
}

.grid-33-66 {
  grid-template-columns: 32% 64%;
}

.grid-thirds {
  grid-template-columns: 32% 32% 32%;
}

.grid.spaced > div {
  padding-right: 2rem;
}
.grid.spaced-wide > div {
  padding-right: 4rem;
}

.col-1 {
  grid-column: span 1;
}

.col-2 {
  grid-column: span 2;
}

.col-3 {
  grid-column: span 3;
}

.col-3 {
  grid-column: span 3;
}

.col-4 {
  grid-column: span 4;
}

.col-5 {
  grid-column: span 5;
}

.col-6 {
  grid-column: span 6;
}

.col-7 {
  grid-column: span 7;
}

.col-8 {
  grid-column: span 8;
}

.col-9 {
  grid-column: span 9;
}

.col-10 {
  grid-column: span 10;
}

.col-11 {
  grid-column: span 11;
}

.col-12 {
  grid-column: span 12;
}

.border-left {
  border-left: 1px dotted silver;
}

.border-right {
  border-right: 1px dotted silver;
}

.border-top {
  border-top: 1px dotted silver;
}

.border-bottom {
  border-bottom: 1px dotted silver;
}

.border-all {
  border: 1px solid silver;
}

.col-full-1 {
  grid-column: span 1;
}
.col-full-2 {
  grid-column: span 2;
}
.col-full-3 {
  grid-column: span 3;
}
.col-full-4 {
  grid-column: span 4;
}
.col-full-5 {
  grid-column: span 5;
}
.col-full-6 {
  grid-column: span 6;
}
.col-full-7 {
  grid-column: span 7;
}
.col-full-8 {
  grid-column: span 8;
}
.col-full-9 {
  grid-column: span 9;
}
.col-full-10 {
  grid-column: span 10;
}
.col-full-11 {
  grid-column: span 11;
}
.col-full-12 {
  grid-column: span 12;
}

@media screen and (max-width: 900px) {
  .grid {
    grid-template-columns: 100%;
  }

  .grid-50-50 {
    grid-template-columns: 100%;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    grid-column: span 12;
  }

  .grid.spaced,
  .grid.spaced-wide {
    padding-right: unset;
  }

  .col-mobile-1 {
    grid-column: span 1;
  }
  .col-mobile-2 {
    grid-column: span 2;
  }
  .col-mobile-3 {
    grid-column: span 3;
  }
  .col-mobile-4 {
    grid-column: span 4;
  }
  .col-mobile-5 {
    grid-column: span 5;
  }
  .col-mobile-6 {
    grid-column: span 6;
  }
  .col-mobile-7 {
    grid-column: span 7;
  }
  .col-mobile-8 {
    grid-column: span 8;
  }
  .col-mobile-9 {
    grid-column: span 9;
  }
  .col-mobile-10 {
    grid-column: span 10;
  }
  .col-mobile-11 {
    grid-column: span 11;
  }
  .col-mobile-12 {
    grid-column: span 12;
  }
}

.form-control {
    padding: 10px;
    font-size: 1rem;
    font-weight: 300;
    width: 95%;
    color: var(--lessBlack);
    border: 1px solid silver;
    font-family: 'Heebo';
}


.form-group > label {
    display: block;
}

.form-group > label.inline {
    display: inline;
}

select.form-control {
    font-size: 1rem;
}

.form-group {
    margin-bottom: 1rem;
}

.width-75 {
    width: 75%;
}

.width-50 {
    width: 50%;
}

.width-25 {
    width: 25%;
}

.width-20 {
    width: 20%;
}

.width-10 {
    width: 10%;
}


.form-label {
    color: var(--ukaBlue);
    font-weight: 400;
}

.form-label::after {
    content: "\A";
    white-space: pre;
}



.form-centered {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}


@media screen and (max-width: 768px) {
    .form-control.width-75 {
        width: 95%;
    }
    
    .form-control.width-50 {
        width: 95%;
    }
    
    .form-control.width-25 {
        width: 95%;
    }

    .form-control.width-10 {
        width: 50%;
    }    
}
.table {
  width: 95%;
  border-spacing: 0;
}

.table.font-90 td {
  font-size: 0.9rem;
}

.table.font-80 td {
  font-size: 0.8rem;
}

.table-grid tbody td {
  padding: 0.5rem;
}

.table-grid-lines tbody td {
  border-bottom: 1px dotted silver;
}

th {
  font-weight: 400;
  text-align: left;
}

tr {
  padding: 2px;
  margin: 0;
}

td.flex-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

table.table-hover tbody tr:hover {
  background-color: var(--lessBlack);
  color: var(--paleWhite);
}
table.table-hover tbody tr:hover td {
  color: var(--paleWhite);
}

table.no-hover * {
  color: var(--nearBlack) !important;
}

table.no-hover thead tr:hover {
  background-color: unset;
  color: unset;
}
table.no-hover tbody tr:hover {
  background-color: unset;
  color: unset;
}

tr .visible-hover {
  display: none;
}

tr:hover .visible-hover {
  display: inline;
}

td {
  font-size: 1rem;
  padding-bottom: 0.5rem;
  vertical-align: top;
}

.spaced {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

td.centre {
  display: flex;
  align-items: center;
  justify-content: center;
}

.width-15 {
  width: 15%;
}

.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.width-75 {
  width: 75%;
}

@media screen and (max-width: 900px) {
  .width-15 {
    width: 95%;
  }
  .width-25 {
    width: 95%;
  }
  .width-50 {
    width: 95%;
  }
  .width-75 {
    width: 95%;
  }

  thead {
    display: none;
  }

  tbody,
  tr,
  td {
    display: block;
    text-align: unset !important;
  }

  td::before {
    content: attr(data-mobilelabel);
    width: 40%;
    display: inline-block;
    font-weight: bold;
  }

  td:nth-child(99)::before {
    content: attr(data-mobilelabel) "\a";
    white-space: pre-wrap;
    width: 40%;
    display: inline;
    font-weight: bold;
  }

  td a.visible-hover {
    display: inline;
  }

  tr td:last-child {
    margin-bottom: 2rem;
  }
}

.mr-1x {
  margin-right: 1rem;
}

.mr-2x {
  margin-right: 2rem;
}

.mr-3x {
  margin-right: 3rem;
}

.mr-4x {
  margin-right: 4rem;
}

.mr-5x {
  margin-right: 5rem;
}

.ml-1x {
  margin-left: 1rem;
}

.ml-2x {
  margin-left: 2rem;
}

.ml-3x {
  margin-left: 3rem;
}

.ml-4x {
  margin-left: 4rem;
}

.ml-5x {
  margin-left: 5rem;
}

.mb-small {
  margin-bottom: 0.5rem;
}

.mb-1x {
  margin-bottom: 1rem;
}

.mb-2x {
  margin-bottom: 2rem;
}

.mb-3x {
  margin-bottom: 3rem;
}

.mb-4x {
  margin-bottom: 4rem;
}

.mb-5x {
  margin-bottom: 5rem;
}

.mt-1x {
  margin-top: 1rem;
}

.mt-2x {
  margin-top: 2rem;
}

.mt-3x {
  margin-top: 3rem;
}

.mt-4x {
  margin-top: 4rem;
}

.mt-5x {
  margin-top: 5rem;
}

.pr-1x {
  padding-right: 1rem;
}

.pr-2x {
  padding-right: 2rem;
}

.pr-3x {
  padding-right: 3rem;
}

.pr-4x {
  padding-right: 4rem;
}

.pr-5x {
  padding-right: 5rem;
}

.pl-1x {
  padding-left: 1rem;
}

.pl-2x {
  padding-left: 2rem;
}

.pl-3x {
  padding-left: 3rem;
}

.pl-4x {
  padding-left: 4rem;
}

.pl-5x {
  padding-left: 5rem;
}

.pb-1x {
  padding-bottom: 1rem;
}

.pb-2x {
  padding-bottom: 2rem;
}

.pb-3x {
  padding-bottom: 3rem;
}

.pb-4x {
  padding-bottom: 4rem;
}

.pb-5x {
  padding-bottom: 5rem;
}

.pt-1x {
  padding-top: 1rem;
}

.pt-2x {
  padding-top: 2rem;
}

.pt-3x {
  padding-top: 3rem;
}

.pt-4x {
  padding-top: 4rem;
}

.pt-5x {
  padding-top: 5rem;
}

@media screen and (max-width: 768px) {
  .pl-5x {
    padding-left: unset;
  }
}

/* Switch */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--success);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--success);
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
/* End switch */
:root {
  --ukaBlue: rgb(200, 60, 155);
  --ukaRed: rgb(235, 160, 60);
  --success: rgb(109, 171, 84);
  --paleWhite: #efefef;
  --silver: #dedede;
  --nearBlack: #444;
  --lessBlack: #666;
}

html,
body {
  font-family: "Oxygen";
  font-weight: 200;
  font-size: 1rem;
  background-color: #efefef;
  background-color: var(--paleWhite);
  color: #444;
  color: var(--nearBlack);
  margin: 0;
  line-height: 1.6rem;
}

p {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

a {
  text-decoration: none;
  color: rgb(200, 60, 155);
  color: var(--ukaBlue);
}

.text-link {
  color: rgb(200, 60, 155);
  color: var(--ukaBlue);
}

.wtf {
  height: 25vw;
}

.narrow-lines {
  line-height: 1rem;
}

h1,
h2,
h3 {
  font-weight: 600;
  padding: 0;
  margin: 0;
}

h4,
h5,
h6 {
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.container {
  width: 95%;
  max-width: 1366px;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.5rem;
}

.sub-container {
  padding: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.semi-trans {
  opacity: 0.2;
}

.sub-menu {
  line-height: 2rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  border-bottom: 1px dotted rgb(200, 60, 155);
  border-bottom: 1px dotted var(--ukaBlue);
}

small {
  font-size: 0.8rem !important;
}

.text-white {
  color: #efefef !important;
  color: var(--paleWhite) !important;
}

.text-red {
  color: rgb(235, 160, 60) !important;
  color: var(--ukaRed) !important;
}

.text-green {
  color: green;
}

.text-blue {
  color: rgb(200, 60, 155) !important;
  color: var(--ukaBlue) !important;
}

.text-bold {
  font-weight: 400;
  color: #444;
  color: var(--nearBlack);
}

.text-center {
  text-align: center;
}
.sponsor-image-row {
  display: flex;
  flex-wrap: wrap;
}
.sponsor-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
}

.sponsor-image {
  max-height: 50px;
  width: auto;
  margin-bottom: 12px;
}

.sponsor-image-delete-link {
  color: rgb(235, 160, 60);
  color: var(--ukaRed);
  cursor: pointer;
}

ul.no-bullets {
  list-style-type: none;
}

.ring {
  min-height: 100px;
  border: 1px solid silver;
  padding: 10px;
  margin-right: 5px;
}

.ring-class {
  padding: 5px;
}
.ring-class-selected {
  background: rgb(200, 60, 155);
  background: var(--ukaBlue);
  color: #efefef;
  color: var(--paleWhite);
  border-radius: 5px;
}

.ring-boxed {
  border: 2px solid black;
  padding: 5px;
}

@media screen and (min-width: 900px) {
  .hidden-large {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .hidden-mobile {
    display: none !important;
  }
}


.logo-container {
    height: 100px;
    text-align: center;
}
footer {
    margin-top: 5rem;
    background-color: var(--ukaBlue);
    color: var(--paleWhite);
    font-weight: 200;
    font-size: 0.9rem;
    padding: 10px;
}

footer span {
    font-weight: 600;
}
.quote {
    font-family: 'Nothing You Could Do';
    font-size: 1.2rem;
    color: var(--ukaRed);
}


.reg-progress-list {
    list-style-type: none;
}

.reg-progress-list li {
    margin-bottom: 5px;
}

.login-text {
    border: none;
    border-bottom: 1px solid silver;
    padding-left: none;
    outline: none;
}
.Message {
    width: 100%;
    margin-top: 1.2rem;
    border-radius: 5px;
    padding: 0.3rem;
    padding-left: 1rem;
}

.Message-Green {
    background: green;
    color: var(--paleWhite);
}

.Message-Red {
    background:var(--ukaRed);
    color: var(--paleWhite);
}
/* W3Schools modal */

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 900; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */
  max-height: 90vh;
  overflow-y: scroll;
}

.modal-content p,
td,
li,
label {
  color: var(--lessBlack);
}

.modal-content-narrow {
  width: 25%;
}
.modal-content-wide {
  width: 95%;
}

.fadeIn {
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 100%;
    margin: 50% auto;
  }
}

.members-bar {
    background: var(--ukaBlue);
    padding-left: 1rem;
    color: var(--paleWhite);
    display: grid;
    grid-template-columns: 25% 67.5% 7.5%;
}


@media screen and (max-width: 768px) {

    .members-bar {
        grid-template-columns: 100%;
    }

}
.navbar {
  border-bottom: 1px solid var(--ukaRed);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

a.nav-item {
  display: inline;
  margin-right: 0.5rem;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  color: var(--ukaBlue);
  font-weight: 300;
  font-size: 0.9rem;
}
a.nav-item:hover {
  background-color: var(--silver);
}

a.nav-item:focus {
  outline: none;
}

a.nav-item.admin-link {
  justify-content: flex-end;
}

.responsive-menu-trigger {
  display: none;
  padding: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .navbar {
    flex-direction: column;
  }

  a.nav-item {
    margin-bottom: 0.2rem;
    display: none;
    order: 2;
  }

  a.nav-item.visible {
    display: block;
    font-size: 1.2rem;
  }

  .responsive-menu-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 1;
  }
}

.action-needed {
    background-color: var(--ukaRed);
    color: var(--paleWhite);
}
.showsbar {
    background: var(--ukaRed);
    color: #efefef;
    padding: 0.5rem;
}
tr.completed {
    background-color: var(--paleWhite);
    color: var(--lessBlack);
}
