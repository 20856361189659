.table {
  width: 95%;
  border-spacing: 0;
}

.table.font-90 td {
  font-size: 0.9rem;
}

.table.font-80 td {
  font-size: 0.8rem;
}

.table-grid tbody td {
  padding: 0.5rem;
}

.table-grid-lines tbody td {
  border-bottom: 1px dotted silver;
}

th {
  font-weight: 400;
  text-align: left;
}

tr {
  padding: 2px;
  margin: 0;
}

td.flex-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

table.table-hover tbody tr:hover {
  background-color: var(--lessBlack);
  color: var(--paleWhite);
}
table.table-hover tbody tr:hover td {
  color: var(--paleWhite);
}

table.no-hover * {
  color: var(--nearBlack) !important;
}

table.no-hover thead tr:hover {
  background-color: unset;
  color: unset;
}
table.no-hover tbody tr:hover {
  background-color: unset;
  color: unset;
}

tr .visible-hover {
  display: none;
}

tr:hover .visible-hover {
  display: inline;
}

td {
  font-size: 1rem;
  padding-bottom: 0.5rem;
  vertical-align: top;
}

.spaced {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

td.centre {
  display: flex;
  align-items: center;
  justify-content: center;
}

.width-15 {
  width: 15%;
}

.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.width-75 {
  width: 75%;
}

@media screen and (max-width: 900px) {
  .width-15 {
    width: 95%;
  }
  .width-25 {
    width: 95%;
  }
  .width-50 {
    width: 95%;
  }
  .width-75 {
    width: 95%;
  }

  thead {
    display: none;
  }

  tbody,
  tr,
  td {
    display: block;
    text-align: unset !important;
  }

  td::before {
    content: attr(data-mobilelabel);
    width: 40%;
    display: inline-block;
    font-weight: bold;
  }

  td:nth-child(99)::before {
    content: attr(data-mobilelabel) "\a";
    white-space: pre-wrap;
    width: 40%;
    display: inline;
    font-weight: bold;
  }

  td a.visible-hover {
    display: inline;
  }

  tr td:last-child {
    margin-bottom: 2rem;
  }
}
