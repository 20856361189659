.alert {
    padding: 5px;
    font-weight: 300;
}

.alert-success {
    background-color: var(--success) !important;
    color: var(--paleWhite) !important;
}

.alert-error {
    background-color: var(--ukaRed) !important;
    color: var(--paleWhite) !important;
}

.alert-warning {
    background-color: rgba(200, 175, 80, 0.5);
}

.alert-tint {
    border: 1px solid silver;
}