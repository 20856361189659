.mr-1x {
  margin-right: 1rem;
}

.mr-2x {
  margin-right: 2rem;
}

.mr-3x {
  margin-right: 3rem;
}

.mr-4x {
  margin-right: 4rem;
}

.mr-5x {
  margin-right: 5rem;
}

.ml-1x {
  margin-left: 1rem;
}

.ml-2x {
  margin-left: 2rem;
}

.ml-3x {
  margin-left: 3rem;
}

.ml-4x {
  margin-left: 4rem;
}

.ml-5x {
  margin-left: 5rem;
}

.mb-small {
  margin-bottom: 0.5rem;
}

.mb-1x {
  margin-bottom: 1rem;
}

.mb-2x {
  margin-bottom: 2rem;
}

.mb-3x {
  margin-bottom: 3rem;
}

.mb-4x {
  margin-bottom: 4rem;
}

.mb-5x {
  margin-bottom: 5rem;
}

.mt-1x {
  margin-top: 1rem;
}

.mt-2x {
  margin-top: 2rem;
}

.mt-3x {
  margin-top: 3rem;
}

.mt-4x {
  margin-top: 4rem;
}

.mt-5x {
  margin-top: 5rem;
}

.pr-1x {
  padding-right: 1rem;
}

.pr-2x {
  padding-right: 2rem;
}

.pr-3x {
  padding-right: 3rem;
}

.pr-4x {
  padding-right: 4rem;
}

.pr-5x {
  padding-right: 5rem;
}

.pl-1x {
  padding-left: 1rem;
}

.pl-2x {
  padding-left: 2rem;
}

.pl-3x {
  padding-left: 3rem;
}

.pl-4x {
  padding-left: 4rem;
}

.pl-5x {
  padding-left: 5rem;
}

.pb-1x {
  padding-bottom: 1rem;
}

.pb-2x {
  padding-bottom: 2rem;
}

.pb-3x {
  padding-bottom: 3rem;
}

.pb-4x {
  padding-bottom: 4rem;
}

.pb-5x {
  padding-bottom: 5rem;
}

.pt-1x {
  padding-top: 1rem;
}

.pt-2x {
  padding-top: 2rem;
}

.pt-3x {
  padding-top: 3rem;
}

.pt-4x {
  padding-top: 4rem;
}

.pt-5x {
  padding-top: 5rem;
}

@media screen and (max-width: 768px) {
  .pl-5x {
    padding-left: unset;
  }
}
